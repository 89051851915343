<template>
  <section class="m-record m-scroll" @scroll="handleScroll($event)">

    <!-- 头部导航 -->
    <m-header title="浏览记录">
      <img src="@/assets/record/icon_qingchu@2x.png" slot="right" alt="clear" @click="onClear">
    </m-header>

    <!-- 浏览记录 -->
    <van-list  v-model="loading" :immediate-check="false"	:finished="finished" finished-text="暂无数据" class="list">
      <div class="">
        <van-swipe-cell v-for="(it,index) in data" :key="index">
            <div class="item-wrap" >
              <div class="avatar" @click="$router.push({path: `/zh/goods/detail/${it.id}`, query: {pre: it.pre_info.is_pre == 1 ? 1 : 0}})">
                <img :src="it.image_url" alt="img">
                <div class="eleven" v-if="it.merchant_id == 1 && it.pre_info.is_pre == 0 && elevenShow"></div>
              </div>
              <div class="info van-hairline--bottom">
                <p @click="$router.push({path: `/zh/goods/detail/${it.id}`, query: {pre: it.pre_info.is_pre == 1 ? 1 : 0}})">{{it.goods_info ? it.goods_info.name : ''}}</p>
                <div>
                  <div>
                    <p v-if="it.pre_info && it.pre_info.pre_min_price != null">
                      <span>$</span><span>{{it.pre_info.pre_min_price}}</span>
                    </p>
                    <p v-else>
                      <span>$</span><span>{{(it.activity_min_price *1).toFixed(0) != 0 ? it.activity_min_price : (it.min_price *(it.off == 0 ? 1 : it.off/100)).toFixed(2)}}</span>
                      <span v-if="(it.activity_min_price *1).toFixed(0) != 0 ? true : it.off!= 0">${{it.min_price}}</span>
                    </p>
                  </div>
                  <img src="@/assets/index/iocn-gouwu@2x.png" alt="" @click="handleCart(it.id, $event)">
                </div>
              </div>
            </div>

          <template #right>
            <span class="delete" @click="delRecordHandle(it.id, index)">
              <img src="@/assets/record/icon_delte@2x.png" alt="">
            </span>
          </template>
        </van-swipe-cell>

        <!-- 选择SKU 加入购物车 -->
        <m-sku title="加入购物车" :show="skuModal" @close="val=>skuModal=val" @success="handleCartSuccess" :goodsId="goodsId"></m-sku>

        <!-- 加入购物车动画 -->
        <m-animate :start="start" :dom="start_dom" @end="handleCartEnd"></m-animate>

        <!-- 购物车浮窗 -->
        <transition name="van-fade">
            <CartFlex v-if="start" />
        </transition>
      </div>
    </van-list>
  </section>
</template>

<script>
import MHeader  from '@/components/zh/m-header.vue'
import MSku         from '@/components/zh/m-sku.vue'
import MAnimate     from '@/components/zh/cart-animate.vue'
import CartFlex     from '@/components/zh/cart-flex.vue'

import { getRecord, delRecord, delAllRecord } from '@/api/zh/mine.js'
export default {
  name:'Index',
  components:{ MHeader, MSku, MAnimate, CartFlex },
  data(){
    return {
      data:[],
      loading:false,
      finished:true,
      page: 1,
      islock: false,
      goodsId: 0,
      start: false,      // 是否开启加入购物车动画
      start_dom: null,   // 开启动画的起点DOM 
      skuModal: false,    // 控制显示 选择sku加入购物车面板
      elevenShow: true // 圣诞活动边框显示
    }
  },
  computed: {
    checkedAll: {
      set(){
        return false
      },
      get(){
        let bol = false
        this.data.forEach(e => {
          if(!e.checked){
            bol = true
          }
        })
        if(bol){
          return false
        }else{
          return true
        }
      }
    },
  },
  methods:{
    // 内容滑动事件
    handleScroll(e){
      if(e.target.scrollHeight - e.target.clientHeight- e.target.scrollTop <= 50){
        if(!this.islock){
          e.preventDefault()
          e.stopPropagation()
          this.loading = true
          this.islock = true
          this.page++
          this.getRecordHandle()
        }
      }
    },
    // 点击头部编辑
    onClear(){
      this.$dialog.confirm({
        title: '清除所有',
        message: '确定要清除所有游览记录吗？',
      }) .then(() => {
        delAllRecord().then(res => {
          if(res) {
            this.$notify({ type: 'success', message: res.msg });
            this.data = []
          }else{
            this.$notify({ type: 'warning', message: res.msg });
          }
        })
      })
      .catch(() => {
        // on cancel
      });
    },
    getRecordHandle() {
      // 获取浏览记录  足迹
      getRecord({page: this.page}).then(res => {
        this.loading = false
        this.islock = false
        if(res) {
          this.data = this.data.concat(res.data.data)
        }else{
          this.finished = true
        }
      })
    },
    delRecordHandle(id, index) {
      // 获取浏览记录  足迹
      delRecord(id).then(res => {
        if(res) {
          this.$notify({ type: 'success', message: res.msg });
          this.data.splice(index, 1)
        }else{
          this.$notify({ type: 'warning', message: res.msg });
        }
      })
    },
    handleCart(goods, e){
        // 商品列表购物车点击事件
        this.goodsId = goods
        this.skuModal  = true
        this.start_dom = e 
    },
    handleCartSuccess(){
        // sku 弹窗加入购物车成功回调
        this.skuModal = false
        let timer = setTimeout( () => {
            this.start  = true
            clearTimeout(timer)
        },300)
    
    },
    handleCartEnd(){
        // 加入购物车动画结束
        this.start = false
    }
  },
  created() {
    this.getRecordHandle()
  },
}
</script>

<style lang="less" scoped>
@import './record.less';
</style>